
.car__card{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //min-width: 280px;
}
.car__card-taglist{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 35px;
  .car__card-tag{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    background: #E7E7E8;
    border-radius: 2px;
    &.free{
      background: rgba(64, 129, 4, 0.2);
    }
    &.not-free{
      color:$grayColor;
    }
  }
  @include l-lg{
    gap: 7px;
  }
}
.car__card-image{
  display: block;
  width: 100%;
  height: 150px;
  margin-bottom: 55px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @include l-lg{
    height: 160px;
    margin-bottom: 15px;
  }
}
.car__card-title{
  font-family: 'Inter';
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: $textColor;
  .model{
    color: $redColor;
  }
  @include l-lg{
    font-size: 22px;
    margin-bottom: 10px;
  }
}
.car__card-payment{
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
  &-value{
    margin-top: 5px;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 15px;
  }
}
.car__card-price{
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 15px;
  &-value{
    font-weight: 700;
    font-size: 16px;
  }
}
.car__card-same{
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
  display: flex;
  width: 100%;
  gap: 10px;
  //justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: $textColor;
  text-decoration: none;
  &:hover, &:active{
    color: $textColor;
    text-decoration: underline;
  }
  &-image{
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
}