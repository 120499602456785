
$textColor:#222222;
$selectedColor:#BF3535;


.header-links{
  display: flex;
  column-gap: 60px;
  align-items: center;
}
.header-link{
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  position: relative;
  /* identical to box height, or 20px */

  letter-spacing: 0.02em;
  //text-transform: uppercase;

  padding: 10px 25px;
  transition: all 0.2s ease-in-out;
  /* Text */
  &:after{
    //content: '';
    display: block;
    width: 0;
    height: 3px;
    border-radius: 25px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
    background: $textColor;
  }
  color: $textColor;
  text-decoration: none;
  &:hover{
    //color: $textColor;
    color: $selectedColor;
    &:after{
      width: 50%;
    }
  }
  &.selected{
    color: $selectedColor;
    &:hover{
      color: $selectedColor;
    }
    &:after{
      background: $selectedColor;
    }
  }
  &.light{
    color: white;
    &:hover{
      color: $selectedColor;
    }
    &:after{
      background: $selectedColor;
    }
  }
}

.header-controls{
  column-gap: 20px;
  align-items: center;
  display: none;
  @include md{
    display: flex;
  }
}
.user-btn{
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E7E7E8;
  filter: drop-shadow(0px 0px 10px rgba(55, 73, 96, 0.25));
  border-radius: 25px;
  outline: none;
  background-size: 22px 22px;
  color: $textColor;
  background: transparent;
  transition: all 0.2s ease-in-out;
  background-image: url('./../../img/common/userB.svg');
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  &:hover{
    border-color: $textColor;
    background-image: url('./../../img/common/userB.svg');
  }
  &.light{
    color: #E7E7E8;
    background-image: url('./../../img/common/userW.svg');
    &:hover{
      //color: white;
      border-color: white;
      background-color: white;
      background-image: url('./../../img/common/userB.svg');
      color: $textColor;
    }
  }
}