
.index__logo{
  min-height: 100vh;
  position: relative;
  background-size: cover;
  color: white;
  display: flex;
  //box-shadow: 20px 0px 60px rgba(0, 0, 0, 0.5);
  background-position: center;
  @include l-lg{
    background-position: 60%;
    padding-top: unset;
  }
  .index__logo-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.24) 92.52%);
    z-index: 20;
  }
  .index__logo-content{
    overflow-x: hidden;
    max-width: 100%;
    padding-top: 180px;
    padding-bottom: 100px;
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    justify-content: center;
    .index__logo-header{
      font-weight: 700;
      font-size: 64px;
      line-height: 120%;
      color: white;
      //text-transform: uppercase;
      @include l-xxl{
        font-size: 42px;
      }
      @include l-md{
        text-align: center;
        font-size: 32px;
      }
      .muted{
        font-weight: 400;
        font-size: 56px;
        color: #D9D9D9;
        line-height: 120%;
        @include l-xxl{
          font-size: 38px;
        }
        @include l-md{
          text-align: center;
          font-size: 28px;
        }
      }
    }
    @include l-md{
      padding-top: 80px;
    }
  }
  @include l-md{
    padding-top: 120px;
    padding-bottom: 40px;
  }
  .index__logo-car{
    width: 100%;
  }
}


.indexCalculatorPeriodIndicator{
  line-height: 120%;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.04em;
  @include l-xl{
    font-size: 26px;
  }
  @include l-md{
    font-size: 22px;
  }
  .indexCalculatorPeriodIndicatorComment{
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.04em;
    @include l-md{
      font-size: 14px;
    }
  }
}
.index__logo-comment{
  color: #F2F2F5;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  max-width: 480px;
  @include l-lg{
    margin-top: 60px;
    font-size: 14px;
  }
  @include l-sm{
    font-size: 20px;
    max-width: 100%;
  }
}


.index__steps{
  padding: 160px 0 95px;
  @include l-md{
    padding: 50px 0 30px;
  }
}
.index__step{
  position: relative;
  z-index: 30;
  background: transparent;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 20px;
  //max-width: 350px;
  @include l-xl{
    min-height: 280px;
  }
  @include l-md{
    //padding-left: 20px;
    min-height: 200px;
  }
  .index__step-bg{
    font-family: 'Lato';
    position: absolute !important;
    bottom:0;
    left: 120px;
    line-height: 100%;
    font-size: 220px;
    height: 220px;
    font-weight: bold;
    color:#E7E7E8;
    z-index: 20 !important;
    @include l-xl{
      font-size: 160px;
      height: 160px;
    }
    @include l-md{
      font-size: 120px;
      height: 120px;
    }
  }
  .index__step-header{
    position: relative;
    z-index: 30;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    *{
      z-index: 30;
      position: relative;
    }
    @include l-xl{
      font-size: 36px;
    }
    @include l-sm{
      font-size: 28px;
    }
  }
  .index__step-arrow{
    position: relative;
    z-index: 30;
    //border: 3px solid #BF3535;
    height: 15px;
    width: 100%;
    background-size: 100%;
    background-position: left;
    background-repeat: no-repeat;
    //background: $redColor;
    //transform: matrix(1, 0, 0, -1, 0, 0);
    margin: 5px 0 10px;
  }
  .index__step-text{
    position: relative;
    z-index: 30;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    //min-height: 70px;
  }

  &.last{
    .index__step-arrow{
      object-fit: fill;
      width: calc(100% + 200px);
      @include l-xxxl{
        width: calc(100% + 100px);
      }
      @include l-xxl{
        width: calc(100% + 20px);
      }
      @include l-xl{
        width: 100%;
      }
    }

  }
}


.index__about{
  background: #FFFFFF;
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 800px;
  padding: 120px 0 120px;
  overflow-x: hidden;
  *{
    position: relative;
    z-index: 30;
  }
  @include l-lg{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include l-md{
    grid-template-columns: 1fr;
  }
}
.index__about-bg{
  position: absolute !important;
  z-index: 20!important;
  top: 0;
  //left: calc(70% - 1400px);
  height: 100%;
  background-size: cover;
  //background-position: top;
  width: 100% !important;
  //max-width: 60%;
  clip-path: polygon(0 0, 74% 0%, 34% 100%, 0% 100%);
  background:
    //linear-gradient(227.7deg,
    //      rgba(0, 0, 0, 0.57) 27.81%,
    //      rgba(19, 19, 19, 0.576) 44.96%,
    //      rgba(19, 19, 19, 0.522) 93.65%),
    url("./../../../img/index/about_bg.webp");
    //linear-gradient(117.27deg, #000000 0%, #222222 83.89%) !important;
  background-size: cover;

  @include l-lg{
    clip-path: unset;
  }
}
.index__about-header{
  font-family: 'Inter';
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 60px;
  @include l-xl{
    font-size: 48px;
  }
  @include l-md{
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
}
.index__about-text{
  font-family: 'Inter';
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin-bottom: 35px;
  @include l-md{
    font-size: 16px;
  }
}

.index__about-labels{
  display: flex;
  flex-wrap: wrap;
  gap: 40px 80px;
  margin-top: 80px;
  @include l-md{
    margin-top: 40px;
    margin-bottom: 60px;
  }
}
.index__about-label{
  &-big{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &-small{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;

  }
}

.index__about-badges{
  display: block;
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @include l-lg{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 10px
  }
  @include l-md{
    padding: 0 10px;
  }
}
.index__about-badge{
  position: absolute;
  display: flex;
  gap: 40px;
  &.index-1{
    left: calc(67% - 45px);
    top: 100px;
    max-width: 30%;
  }
  &.index-2{
    left: calc(58% - 45px);
    top: 317px;
    max-width: 40%;
  }
  &.index-3{
    left: calc(48.7% - 45px);
    top: 550px;
    max-width: 40%;
  }
  &.index-4{
    left: calc(41% - 45px);
    top: 740px;
    max-width: 40%;
  }
  @include l-xl{
    &.index-1,&.index-2,&.index-3,&.index-4{
      max-width: 40%;
    }
  }
  @include l-lg{
    color: white;
    position: relative;
    &.index-1,&.index-2,&.index-3,&.index-4{
      max-width: 100%;
      left: unset;
      top:unset;
      position: relative;
      margin-bottom: 20px;
    }
  }

  .index__about-badge-image{
    min-width: 110px;
    min-height: 110px;
    width: 110px;
    height: 110px;
    background: #FFFFFF;
    border: 2px solid #F2F3F6;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 66px;
      height: 66px;
      object-fit: contain;
    }
    @include l-lg{
      min-width: 60px;
      min-height: 60px;
      width: 60px;
      height: 60px;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }

  .index__about-badge-header{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include l-lg{
      color: white;
    }

  }
  .index__about-badge-text{
    font-weight: 600;
    @include l-lg{
      color: #dadada;
    }
  }
}


.index__offers{
  padding-top: 100px;
  padding-bottom: 100px;
  .index__offers-header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 62px;
    line-height: 120%;
    margin-bottom: 60px;
    align-items: flex-end;
    .index__offers-header-link{
      font-family: 'Inter';
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: 0.01em;
      text-align: end;
      text-transform: uppercase;
      color: #606569;
      position: relative;
      text-decoration: none;
      padding-bottom: 12px;
      transition: all 0.15s ease-in-out;
      &:hover, &:active{
        color: $redColor;
        text-decoration: none;
      }
      &::after{
        position: absolute;
        bottom:0px;
        left:0;
        width: 100%;
        height: 12px;
        background-image: url("./../../../img/index/gray-arrow.svg");
        content: '';
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover::after{
        //background-image: url("./../../../img/index/dark-arrow.svg");
        background-image: url("./../../../img/index/red-arrow.svg");
      }
    }
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include l-xl{
      font-size: 52px;
    }
    @include l-lg{
      font-size: 48px;
    }
    @include l-md{
      font-size: 34px;
      margin-bottom: 30px;
    }
  }
  .index-offers-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    @include l-xl{
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include l-lg{
      grid-template-columns: 1fr 1fr;
    }
    @include l-sm{
      grid-template-columns: 1fr;
    }
  }
  .index-offers-brands{
    margin-top: 100px;
    display: flex;
    gap: 120px;
    overflow-x: scroll;
    padding-bottom: 25px;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */

      scrollbar-width: auto;
      scrollbar-color: #5c5c5c #ffffff;


    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      border-radius: 6px;
      width: 5px !important;
      border: 0px solid #ffffff;
    }


    @include l-md{
      margin-top: 50px;
      column-gap: 40px;
    }
  }
  @include l-md{
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.index-offers-brand{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  img{
    height: 80px;
    max-width: 200px;
    object-fit: contain;
  }
}