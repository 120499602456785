
$uiRedColor: #FF1F00;
$defaultColor: #222222;

//.form-check{
//  display: flex;
//  padding-left: 5px;
//  align-items: center;
//}

.modal.widened {
  @include xl{
    .modal-dialog {
      --bs-modal-width: 1200px !important;
    }
  }
}
.modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px) !important;
}
.toast-header{
  background: $textColor;
  color: white;
  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
}
.toast-body{
  background: rgba($textColor, 0.85);
  color: white;
}
.form-check{
  padding-left: 25px;
}
.form-check-input{
  transition: all 0.15s ease-in-out;
  border-color: $defaultColor;
  margin-left: -25px;
  //.form-check-label{
  //  padding-left: 10px;
  //}
  &:checked {
    background-color: white;
    border-color: $uiRedColor;
  }
  &:focus{
    outline: 0;
    box-shadow: unset;
    border-color: $defaultColor;
  }
  &[type=checkbox]{
    margin-left: -24px;
    border-radius: 2px;
    border-width: 1px;
    width: 15px;
    box-shadow: 0 0 0px 1px rgba($textColor, 0.7);
    height: 15px;
    transition: all 0.2s ease-in-out;
    //border: unset;
    //background-image: url("./../img/index/checkbox-empty.svg");
    &:hover{
      border-color: $uiRedColor;
      box-shadow: 0 0 0px 1px rgba($uiRedColor, 0.7);
    }
    &:checked{
      border-color: $uiRedColor;
      box-shadow: 0 0 0px 1px rgba($uiRedColor, 0.7);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23FF1F00%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    }
  }
}

@media (min-width: 1440px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1400px;
    padding: 0;
  }
}

.modal-content{
  border-radius:3px!important;
  //overflow: hidden!important;
}