
.footer__map{
  position: relative;
  z-index: 20;
  display: flex;
  width: 100%;
  height: 100%;
  &:after{
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 30;
    position: absolute;
    content: '';
  }
  .map_container{
    position: relative;
    display: flex;
    filter: grayscale(0.7);
    min-height: 400px;
    height: 100%;
    width: 100%;
  }
}

.footer{
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer__contacts{
  color: white;
  min-height: 650px;
  //line-height: 120%;
  padding-top: 160px;
  max-width: 530px;
  @include l-xl{
    padding-top: 65px;
  }
  .footer-contacts-top{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 60px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 120%;
    padding-bottom: 15px;
    border-bottom: 6px solid $redColor;
    margin-bottom: 80px;
    max-width: 460px;
    @include l-xl{
      margin-bottom: 30px;
    }
    @include l-md{
      padding-bottom: 20px;
      margin-bottom: 30px;
      font-size: 42px;
    }
  }
  .footer-contacts-blocks{
    margin-bottom: 80px;
    @include l-xl{
      margin-bottom: 35px;
    }
  }
  .footer-contacts-block{
    display: flex;
    gap: 20px;
    color: white;
    margin-bottom: 40px;
    @include l-md{
      margin-bottom: 20px;
    }
    a{
      color: white;
      text-decoration: none;
      &:hover, &:active{
        color: white;
        text-decoration: underline;
      }
    }
    .footer-contacts-block-image{
      width: 24px;
      height: 24px;
      margin-top: 5px;
    }
    .footer-contacts-block-header{
      font-family: 'Inter';
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include l-md{
        margin-bottom: 10px;
      }
    }
    .footer-contacts-block-text{
      font-family: 'Inter';
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
      @include l-md{
        font-size: 14px;
      }
    }
  }

  .footer-contacts-links{
    margin-bottom: 135px;
    display: flex;
    gap: 15px;
    @include l-xl{
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .footer-contacts-link{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 2px solid #FFFFFF;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 0px 11.1367px rgba(0, 0, 0, 0.3));
    border-radius: 100px;
    .hover-image{
      display: none;
    }
    .hover-image, .default-image{
      width: 22px;
      height: fit-content;
    }
    &:hover{
      border: 2px solid #FFFFFF;
      background: white;
      .default-image{
        display: none;
      }
      .hover-image{
        display: block;
      }
    }
  }
}
.footer-contacts-bottom{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  align-items: flex-end;
  @include l-xl{
    flex-direction: column;
  }
}
.footer-contacts-bottom-element{
  color: #BABCBF;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  a{
    text-decoration: underline;
  }
  &:hover, &:active{
    color: #BABCBF;
    text-decoration: none;
  }
}
.footer__map-form{
  position: absolute;
  left: 20px;
  bottom: 50px;
  z-index: 50;
  @include l-md{
    position: relative;
    margin: 2rem 0.5rem;
    max-width: 100%;
    left: unset;
    bottom: unset;
  }
}
.footer__map-block{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 60%;
  max-width: 1100px;
  @include l-xl{
    width: 50%;
  }
  @include l-md{
    position: relative;
    width: 100%;
    margin: 1rem 0;
  }
  &.full{
    width: 100% !important;
    position: relative;
    max-width: unset;
    .map_container{
      min-height: 650px;
      @include l-md{
        min-height: 450px;
      }
    }
  }
}