*{
  box-sizing: border-box;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-default{
  cursor: default;
}
.sticky-no-scrollbar{
  width: 100%;
  max-height: calc(100vh - 120px);
  top: 120px;
  overflow-y: scroll;
  position: sticky;
  //padding-left: 10px;
  z-index: 90;
  &.top100{
    max-height: calc(100vh - 100px);
    top: 100px;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-button {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #E7E7E8;
    border: 0px none #ffffff;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #E7E7E8;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #E7E7E8;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-track:active {
    background: #ffffff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.text-default{
  color:$textColor !important;
}
.text-red-color{
  color:$redColor !important;
}
.text-red{
  color:$redColor !important;
}
.text-gray-color{
  color:$grayColor !important;
}
.text-lightgray{
  color:$lightGrayColor !important;
}

.text-hover-default{
  &:hover {
    color: $textColor !important;
  }
}
.text-hover-red-color{
  &:hover{
    color:$redColor !important;
  }
}
.text-hover-gray-color{
  &:hover {
    color: $grayColor !important;
  }
}
.text-hover-lightgray{
  &:hover {
    color: $lightGrayColor !important;
  }
}
.text-hover-white{
  &:hover {
    color: white !important;
  }
}

.bg-hover-default{
  &:hover {
    background-color: $textColor !important;
  }
}
.bg-hover-red-color{
  &:hover{
    background-color:$redColor !important;
  }
}
.bg-hover-gray-color{
  &:hover {
    background-color: $grayColor !important;
  }
}
.bg-hover-lightgray{
  &:hover {
    background-color: $lightGrayColor !important;
  }
}
.bg-hover-white{
  &:hover {
    background-color: white !important;
  }
}

.default-transition{
  transition: all 0.15s ease-in-out;
}
.long-transition{
  transition: all 0.3s ease-in-out;
}
.rotate-45{
  transform: rotate(45deg);
}
.rotate-90{
  transform: rotate(90deg);
}
.rotate-180{
  transform: rotate(180deg);
}
.rotate-270{
  transform: rotate(270deg);
}
.rotate-360{
  transform: rotate(360deg);
}
.rotate-45-back{
  transform: rotate(-45deg);
}
.rotate-90-back{
  transform: rotate(-90deg);
}
.rotate-180-back{
  transform: rotate(-180deg);
}
.rotate-270-back{
  transform: rotate(-270deg);
}
.rotate-360-back{
  transform: rotate(-360deg);
}

.bg-default-color{
  background-color:$textColor !important;
}
.bg-red-color{
  background-color:$redColor !important;
}
.bg-gray-color{
  background-color:$grayColor !important;
}
.ls-001{
  letter-spacing: 0.01em;
}
.ls-002{
  letter-spacing: 0.02em;
}
.ls-003{
  letter-spacing: 0.03em;
}
.ls-004{
  letter-spacing: 0.04em;
}

*{
}
html {
  max-width: 100%;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 120%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  max-width: 100%;
  letter-spacing: 0.01em;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  .site{
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    main{
      flex-grow: 1;
    }
  }

}
main{
  min-height: 70vh;
  position: relative;
}
.default-link{
  color: #BABCBF;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  display: inline;
  background: transparent;
  border: unset;
  outline: none;
  &:hover{
    color: #808080;
    //text-decoration: underline ;
  }
  &:active{
    color: #494949;
  }
  &.dark{
    color: $textColor ;
    &:active{
      color: #BABCBF;
    }
  }
  &.underlined{
    text-decoration: underline ;

    &:hover, &:active{
      text-decoration: none ;
    }
  }
}

.contacts__form{
  padding: 30px 40px;
  background: white;
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  min-width: 540px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include l-md{
    max-width: 100%;
    min-width: unset;
  }
  &.contacts__form-big{
    padding: 60px 100px;
    max-width: unset;
    min-height: 600px;
    height: 100%;
  }
}
.contacts__form-input{
  outline: none;
  border: unset;
  border-bottom: 2px solid #E7E7E8;
  width: 100%;
  padding: 10px 15px;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 140%;
  transition: all 0.2s ease-in-out;
  position: relative;
  &::placeholder{
    color:#BABCBF;
  }
  &:focus{
    border-bottom: 2px solid $textColor;
  }
  &.error{
    border-bottom: 2px solid $redColor;
    color: $redColor;
    z-index: 15;
    &:before{
      display: flex;
      align-items: center;
      text-align: end;
      content: "" attr(data-error) "";
      color: $redColor;
      position: absolute;
      z-index: 20;
      right: 0;
      top: 0;
      height: 100%;
      font-size: 12px;
      max-width: 30%;
      width: fit-content;
    }
  }
  &.small{
    padding: 7px 5px;
  }
}
.contacts__form-textarea-container{
  position: relative;
  &:after{
    position: absolute;
    top:0;
    left: 0;
    content:'';
    display: block;
    width: 100%;
    height: 30px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(white,0) 0%, rgba(white,1) 100%);
    z-index: 50;
    pointer-events: none;
  }
  .contacts__form-textarea{
    @extend .contacts__form-input;
    min-height: 100px;
    resize: none;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-button {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #E7E7E8;
      border: 0px none #ffffff;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #E7E7E8;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #E7E7E8;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
      border: 0px none #ffffff;
      border-radius: 1px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #ffffff;
    }
    &::-webkit-scrollbar-track:active {
      background: #ffffff;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }

  }

}
.site-btn{
  display: block;
  width: fit-content;
  outline: none;
  background: white;
  border: 2px solid $redColor;
  color: $textColor;
  font-size: 14px;
  font-weight: 600;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  border-radius: 2px;
  line-height: 140%;
  transition: all 0.15s ease-in-out;
  padding: 10px 25px;
  text-transform: uppercase;
  text-decoration: none;
  &.small{
    padding: 10px 25px;
    //font-weight: 500;
  }
  &:hover{
    background: $redColor;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    color: white;
    border-color: $redColor;
  }
  &:active{
    background: $redColor;
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-color: $redColor;
  }
  &.big{
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 600;
  }
  &.dark{
    border-color: $textColor;
    &:hover{
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-color: $textColor;
      background: $textColor;
      color: white;
    }
    &:active{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-color: $textColor;
      background: $textColor;
      color: white;
    }
  }
  &.gray{
    border-color: $grayColor;
    color: $grayColor;
    box-shadow: unset;
    &:hover{
      background: white;
      box-shadow: unset;
      border-color: $grayColor;
      color: $grayColor;
    }
    &:active{
      background: white;
      box-shadow: unset;
      border-color: $grayColor;
      color: $grayColor;
    }
  }
  &.light{
    background: transparent;
    color: white;
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 0px 10px rgba(55, 73, 96, 0.25));
    border-radius: 2px;
    &:hover{
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      color: $textColor;
    }
    &:active{
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      color: $textColor;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

._alert{
  background: #FFFFFF;
  border: 1px solid rgba(191, 53, 53, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px 20px;
  z-index: 30;
  position: relative;
  line-height: 120%;
  a{
    color: $redColor;
    text-decoration: none;
    &:hover, &:active{
      color: $redColor;
      text-decoration: underline;
    }
  }
  ._alert__image{
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  ._alert__content{
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    flex-grow: 1;
    @include l-md{
      font-size: 13px;
    }
  }
  ._alert__close{
    border: unset;
    outline: none;
    background: transparent;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top:0;
    transform: translate(50%, -50%);
    z-index: 40;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease-in-out;
    background: white;
    color:$lightGrayColor;
    &:hover, &:active{
      color:$textColor;
    }
  }
  &.hidden{
    display: none;
  }
}
.overlay-bt{
  background: linear-gradient(0deg, rgba(black, 0.5) 0%,  rgba(black, 0.3) 40%, rgba(black, 0) 100%);
}

.scroller{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: $textColor;
  border-radius: 10px;
  z-index: 60;
  border: unset;
  box-shadow: unset;
  outline: none;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  &.show{
    opacity: 1;
  }
  &:hover{
    background: #444444;
  }
}

.cookies{
  display: flex;
  //align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  background: rgba(0, 0, 0, 0.5);
  background-position: 20px center;
  background-repeat: no-repeat;
  background-image: url("./../img/common/cookies.png");
  background-size: 40px 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1px);

  border-radius: 5px;
  gap: 10px;
  transform: translate(-50%, 100%);
  max-width: 400px;
  padding: 10px 20px 10px 80px;
  color:white;
  font-weight: 300;
  font-size: 14px;
  z-index: 60;
  border: unset;
  outline: none;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  &.show{
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  &:hover{
    background-color: #444444;
  }
}


.modal-template{
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  .modal-image-block{
    width: 55%;
    @include l-md{
      display: none;
    }
    display: flex;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-end;
    padding: 30px 40px;
  }
  .modal-content-block-container{
    width: 45%;
    max-width: 45%;
    @include l-md{
      max-width: unset;
    }

  }
  .modal-content-block{
    min-height: 760px;
    padding: 30px 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: 100%;
    @include l-xl{
      min-height: 650px;
    }
    @include l-md{
      min-height: 600px;
    }
  }



}
.call-request-text-header{
  font-family: 'Inter';
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 15px;
}
.call-request-text{
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.call-content-text-header{
  font-family: 'Inter';
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.call-content-text{
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #606569;
  margin-bottom: 5px;
}

.call-content-input{
  padding-bottom: 15px;
  position: relative;
  font-size: 16px;
  &.error{
    .contacts__form-input{
      z-index: 15;
    }
    &:before{
      display: flex;
      align-items: center;
      text-align: end;
      content: "" attr(data-error) "";
      color: $redColor;
      position: absolute;
      z-index: 20;
      right: 0;
      top: 0;
      height: 100%;
      font-size: 12px;
      max-width: 50%;
      width: fit-content;
    }
  }
  &.small-form-input{
    &:before{
      bottom: 0px;
      left: 0px;
      right:unset;
      top:unset;
      height: unset;
      font-size: 10px;
      width: 100%;
      max-width: unset;
    }
  }
}
.shadowed-bg{
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
}
.cited{
  padding-left: 36px;
  border-left: 6px solid $redColor;
}
.small-black-btn{
  outline: none;
  padding: 5px 10px;
  color: white;
  background: $textColor;
  border-radius: 2px;
  border: unset;
  transition: all 0.2s ease-in-out;
  &:hover{
    background: #4b4b4b;
  }
}
.cited-block{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 12px 20px 12px 70px;
  position: relative;
  font-size: 15px;
  line-height: 140%;
  font-weight: 500;
  display: block;
  width: fit-content;
  letter-spacing: 0.01em;
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    content: '';
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    background-color: $textColor;
    background-image: url("./../img/common/contract.svg");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}
.leasing__imaged-block{
  padding-left: 118px;
  padding-right: 10px;
  position: relative;
  min-height: 88px;
  &:before{
    position: absolute;
    content: '';
    top:0;
    left: 0;
    height: 88px;
    width: 88px;
    border: 2px solid #F2F3F6;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    background-color: #FFFFFF;
    background-size: 48px 48px;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.li-bank{&:before{background-image: url("./../img/index/bank.png")}}
  &.li-jewel{&:before{background-image: url("./../img/index/jewel.png")}}
  &.li-card{&:before{background-image: url("./../img/index/cards.png")}}
  &.li-document{&:before{background-image: url("./../img/index/document.png")}}
}
.leasing__step{
  padding-top: 177px;
  position: relative;
  line-height: 120%;
  &.first{
    margin-right: 40px;
  }
  &.second{
    margin-right: 40px;
    margin-left: 40px;
  }
  &.third{
    margin-left: 40px;
  }
  .leasing__step-text{
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    z-index: 20;
    margin-bottom: 20px;
    line-height: 120%;
  }
  .leasing__step-bgtext{
    position: absolute;
    right: 0;
    top: 0;
    font-family: "Lato";
    font-size: 220px;
    font-weight: 700;
    line-height: 100%;
    z-index: 15;
    width: fit-content;
    height: 220px;
    color:#E7E7E8;
  }
  .leasing__step-side-image{
    position: absolute;
    height: 195px;
    width: 108px;
    background-image: url("./../img/common/helix.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    left:0;
    bottom:0;
    transform: translate(-50%, 70%);
  }
}

.faq-contacts{
  //margin: 100px 0 90px;
  @extend .cited;
  .faq-contacts-header{
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .faq-contacts-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 10px;
  }
}


.logo-text{
  font-family: 'Inter';
  font-weight: 600;
  font-size: 50px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $textColor;
  margin-bottom: 20px;
}

.foldable{
  margin-bottom: 35px;
  width: 100%;
  .foldable-header{
    width: 100%;
    background: transparent;
    outline: none;
    border: unset;
    display: flex;
    text-align: start;
    gap:20px;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #F2F3F6;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: $textColor;
  }
  .foldable-header-image{
    transition: all 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    object-fit: contain;
    &.foldable-header-image-small{
      width: 18px;
      height: 18px;
    }
    &.turned{
      transform: rotate(-180deg);
    }
  }
  .foldable-content{
    padding: 10px 0 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #222222;
    white-space: pre-wrap;
  }


  &.foldable-small{
    margin-bottom: 20px;
    width: 400px;
    .foldable-header{
      font-size: 14px;
      font-weight: 500;
      padding: 0 0 5px 0;
    }
    .foldable-content{
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
    }
  }
  .foldable-ol, .foldable-ul{
    margin-right: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    li{
      //display: flex;
      padding-bottom: 10px;
      padding-left: 15px;
      position: relative;
      &::before{
        color: $textColor;
        font-size: 12px;
        font-weight: bold;
        //border: 1.5px solid #E7E7E8;
        border-radius: 100%;
        color:#BABCBF;
        width: 20px;
        min-width: 20px;
        height: 17px;

        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0;
        margin-top: -1px;
        line-height: 1.0;
      }
    }
  }
  .foldable-ol li::before{
    content: attr(data-marker);
  }
  .foldable-ul li::before{
    position: absolute;
    top:6px;
    left: 0;
    content: '';
    background: #606569;
    border-radius: 1px;
    height: 5px;
    width: 5px;
    border-radius:20px;
    max-width: 5px;
    min-width: 5px;
    max-height: 5px;
    min-height: 5px;
    display: block;
  }
}

@for $i from 1 through 40 {
  .anim-duration-#{$i*100} {
    transition-duration: $i*100ms !important;
  }
}
@for $i from 1 through 50 {
  .anim-delay-#{$i*100} {
    transition-delay: $i*100ms !important;
  }
}
.anim-enter-opacity{
  @include animated-enter($directionX:'none',$directionY:'none',$intensityX:0,$intensityY:0);
}
@for $i from 1 through 10{
  .anim-enter-start-#{$i}{
    @include animated-enter($directionX:'left',$directionY:'none',$intensityX:$i,$intensityY:0);
  }
  .anim-enter-end-#{$i}{
    @include animated-enter($directionX:'right',$directionY:'none',$intensityX:$i,$intensityY:0);
  }
  .anim-enter-top-#{$i}{
    @include animated-enter($directionX:'none',$directionY:'top',$intensityY:$i,$intensityX:0);
  }
  .anim-enter-bottom-#{$i}{
    @include animated-enter($directionX:'none',$directionY:'bottom',$intensityY:$i,$intensityX:0);
  }
  @for $j from 1 through 10{
    .anim-enter-start-#{$i}-bottom-#{$j}{
      @include animated-enter($directionX:'left',$directionY:'bottom',$intensityX:$i,$intensityY:$j);
    }
    .anim-enter-end-#{$i}-bottom-#{$j}{
      @include animated-enter($directionX:'right',$directionY:'bottom',$intensityX:$i,$intensityY:$j);
    }
    .anim-enter-start-#{$i}-top-#{$j}{
      @include animated-enter($directionX:'left',$directionY:'top',$intensityX:$i,$intensityY:$j);
    }
    .anim-enter-end-#{$i}-top-#{$j}{
      @include animated-enter($directionX:'right',$directionY:'top',$intensityX:$i,$intensityY:$j);
    }
  }
}

@for $i from 0 through 10 {
  .opacity-#{$i*10} {
    opacity: $i*10% !important;
  }
}
@for $i from 6 through 38 {
  .font-size-#{$i*2} {
    font-size: $i*2px !important;
  }
}
@for $i from 20 through 30 {
  .line-height-#{$i*5} {
    line-height: $i*5% !important;
  }
}
@for $i from 1 through 20 {
  .gap-px-#{$i*5} {
    gap: $i*5px !important;
  }
  .gap-x-px-#{$i*5} {
    column-gap: $i*5px !important;
  }
  .gap-y-px-#{$i*5} {
    row-gap: $i*5px !important;
  }
}
@for $i from 1 through 60 {
  .m-px-#{$i*5} {
    margin: $i*5px !important;
  }
  .mx-px-#{$i*5} {
    margin-left: $i*5px !important;
    margin-right: $i*5px !important;
  }
  .ms-px-#{$i*5} {
    margin-left: $i*5px !important;
  }
  .me-px-#{$i*5} {
    margin-right: $i*5px !important;
  }
  .my-px-#{$i*5} {
    margin-top: $i*5px !important;
    margin-bottom: $i*5px !important;
  }
  .mb-px-#{$i*5} {
    margin-bottom: $i*5px !important;
  }
  .mt-px-#{$i*5} {
    margin-top: $i*5px !important;
  }

  .p-px-#{$i*5} {
    padding: $i*5px !important;
  }
  .px-px-#{$i*5} {
    padding-left: $i*5px !important;
    padding-right: $i*5px !important;
  }
  .ps-px-#{$i*5} {
    padding-left: $i*5px !important;
  }
  .pe-px-#{$i*5} {
    padding-right: $i*5px !important;
  }
  .py-px-#{$i*5} {
    padding-top: $i*5px !important;
    padding-bottom: $i*5px !important;
  }
  .pb-px-#{$i*5} {
    padding-bottom: $i*5px !important;
  }
  .pt-px-#{$i*5} {
    padding-top: $i*5px !important;
  }
}


.contacts-social{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $textColor;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  border-radius: 100px;
  transition: all 0.1s ease-in-out;

  .hover-image{
    display: none;
  }
  .hover-image, .default-image{
    width: 22px;
    height: 22px;
    object-fit: contain;
  }
  &:hover{
    //border: 2px solid #FFFFFF;
    background: $textColor;
    .default-image{
      display: none;
    }
    .hover-image{
      display: block;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader{
  animation: rotation 2s infinite;
}


.document-page{
  margin: 45px 0 90px;
  .document-title{
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  .document-subtitle{
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
  }
  p {
    padding-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    &.document-not-padded{
      padding-left: 0;
    }
    a {
      color: $textColor;
      transition: all 0.1s ease-in-out;
      &:hover, &:active{
        //text-decoration: none;
        color: $redColor;
      }
    }
  }
}

.back_btn{

  background: #222222;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: white;
  padding: 10px 20px;
  outline: none;
  position: fixed;
  bottom: 20px;
  left:5%;
  box-shadow: unset;
  border: unset;

  &:hover{
    background: #444444;
  }
}

.paginator{
  display: flex;
  gap: 20px;
  .paginator-btn{
    display: flex;
    border: $defaultColor;
    color:$defaultColor;
    outline: none;
    background: white;
    border-radius: 2px;
    border:2px solid $defaultColor;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 9px 14px;
    text-decoration: none;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    transition: all 0.15s ease-in-out;
    &:hover, &:active{
      background: $defaultColor;
      color: white;
    }
    &.active{
      background: $defaultColor;
      color: white;
    }
    &.disabled{
      border-color: $grayColor !important;
      color:  rgba($grayColor, 0.4);
      background: white !important;
    }
  }
  .paginator-arrow{
    @extend .paginator-btn;
    border-color: transparent;
    padding: 9px 0;
    &:hover, &:active{
      background: white;
      color: rgba($defaultColor, 0.7);
    }
    &.disabled{
      border-color: transparent !important;
      color:  rgba($grayColor, 0.4);
      background: white !important;
    }
  }
}